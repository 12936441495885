/* CSS for larger screens */
@media (min-width: 768px) {
    .table-responsive {
      overflow-x: hidden; /* Keep the horizontal scrollbar on larger screens */
    }
  }
  
  /* CSS for smaller screens */
  @media (max-width: 767px) {
    .table-responsive {
      overflow-x: scroll; /* Make the table horizontally scrollable on smaller screens */
    }
  }
  