.todolist-table, .predictionhistory-table {
    thead > tr th:first-child{
        position: sticky;
        left: 0;
        z-index: 1;
    
    } 
    tbody tr > td:first-child{
        position: sticky;
        left: 0;
        z-index:1;
        background-color: white;
    }
}