.container {
    margin-top: 50px;
  }
  
  .card {
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: cardAnimation 0.5s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    font-size: 24px;
    color: white;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .widthdraw .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: linear-gradient(to bottom right, #00c6ff, #0072ff);

  }
  
  .card-body a {
    font-size: 18px;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    color: white;
    transition: background-color 0.3s ease-in-out;
  }
  
  .card-body a:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .form-row {
    margin-bottom: 20px;
  }
  
  .form-row label {
    font-size: 16px;
    color: white;
  }
  
  .form-control {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 6px 12px;
  }
  
  .btn-primary {
    background-color: rgb(21, 7, 211);
    color: white;
    font-size: 18px;
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .btn-primary:hover {
    background-color: rgb(0, 122, 255);
  }
  .btn-animate {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    color: white;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
    overflow: hidden;
  }
  
  .current-income {
    background: linear-gradient(45deg, #0b9e3c, #277cb4);
  }
  
  .total-income {
    background: linear-gradient(45deg,  #0b9e3c, #277cb4);
  }
  
  .btn-animate:hover {
    background-position: right center;
  }
  
  .btn-animate::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
    transform: skewX(-30deg);
    transition: left 0.3s ease-in-out;
  }
  
  .btn-animate:hover::before {
    left: 100%;
  }
  
  @media (max-width: 576px) {
    .btn-animate {
      font-size: 14px;
    }
  }
  
  
  @media (max-width: 576px) {
    .card-body {
      padding: 10px;
    }
  
    .card-title {
      font-size: 20px;
    }
  
    .form-row label {
      font-size: 14px;
    }
  
    .form-control {
      height: 35px;
      font-size: 14px;
      padding: 4px 8px;
    }
  
    .btn-primary {
      font-size: 16px;
      padding: 6px 12px;
    }
  }
  
  @keyframes cardAnimation {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .form-row {
  margin-bottom: 20px;
}

.form-row label {
  font-size: 16px;
  color: white;
}

.form-control {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: none;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 6px 12px;
  font-size: 16px;
  transition: border-color 0.3s ease-in-out;
}

.form-control:focus {
  outline: none;
  border-color: rgb(0, 122, 255);
  box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.3);
}

@media (max-width: 576px) {
  .form-row label {
    font-size: 14px;
  }

  .form-control {
    height: 35px;
    font-size: 14px;
    padding: 4px 8px;
  }
}
