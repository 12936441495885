@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&display=swap');
*{
    font-family: 'poppins' ,sans-serif;
}
.body{
    background-image: url("login.avif");
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100vh;
    
}
.logs{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly; 
        align-items: center;
        margin-bottom: 30px;
      
      
}
.boxlogin{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}
.containerlogin{
    display: flex;
    flex-direction: column;
    padding: 0 15px 20px 15px;
    border: 2px solid rgb(17, 164, 184);
    border-radius: 20px;
    margin-top: 100px;
    background-color:rgb(23, 191, 221);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); 
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}
.containerlogin:hover {
    transform: scale(1.1); 
  }
  
 .spanlogin{
    color: #131212;
    font-size: small;
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;

} 
.header{
    color: #0c0b0b;
    font-size: 30px;
    display: flex;
    justify-content: center;
    padding: 10px 0 10px 0;
    
}

.input-fieldlogin .inputlog{
    height: 45px;
    width: 87%;
    border: none;
    border-radius: 30px;
    color: black;
    font-size: 15px;
    padding: 0 0 0 45px;
    margin: 10px;

    background: rgba(255,255,255,0.1);
    outline: none;

}
.login .submitlogin:hover {
    background-color: aqua;
}
.icons{
    position: relative;
    top: -33px;
    left: 17px;
    color: #fff;
}

.submitlogin{
    border: none;
    border-radius: 30px;
    font-size: 15px;
    height: 45px;
    outline: none;
    width: 85%;
    color: black;
    background: rgba(255,255,255,0.7);
    cursor: pointer;
    transition: .3s ;
    margin-left: 12px;
    margin-top: 5px;
}
.submitlogin:hover{
    box-shadow: 1px 5px 7px 1px rgba(0, 0, 0, 0.2);
}
.two-col{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    font-size: small;
    margin-top: 10px;
}
.one{
    display: flex;
}
label a{
    text-decoration: none;
    color: #fff;
}


.heading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  
.logo-text {
    font-size: 40px;
    font-weight: bold;
    color:navy;
    animation: blinkText 1.5s infinite; 
  }
  
  @keyframes blinkText {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  
  .sub-heading-text {
    font-size: 26px;
    font-weight: bold;
    color:navy; 
    margin-top: 5px;
  }
  
  