
.admin-container{
    display:flex;
 

}
.containers {
  display: flex;


}

.main {
  width: 100%;
}

.sidebar {
  background:rgb(232, 243, 243);
  color: black;
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.logo {
  font-size: 30px;
}

.bars {
  display: flex;
  font-size: 25px;
  
}

.link {
  display: flex;
  color: #0a0a0a;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;

}

@media screen and (max-width: 414px) {
  .link {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    z-index: 10;
  }

  .icon .link_text {
    display:block;
  }
}

@media screen and (max-width: 768px) {
  .link {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
  }

  .icon .link_text {
    display: block;
  }
}

@media screen and (max-width: 375px) {
  .link {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    z-index: 10;
  }

  .icon,
  .link_text {
    display: block;
  }
}

@media screen and (max-width: 390px) {
  .link {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    z-index: 10;
  }

  .icon,
  .link_text {
    display: block;
  }
}

@media screen and (max-width: 914px) {
  .link {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    z-index: 10;
  }

  .icon,
  .link_text {
    display: block;
  }
}

.link:hover {
  background: lightskyblue; 
  color: #000;
  transition: all 0.5s;
}

.active {
  color: #000;
  background:lightskyblue
}

.icon,
.link_text {
  font-size: 15px;
}

