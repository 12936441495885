.table-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    background-color: #f8f8f8;
    box-shadow: 0 0 10px rgba(10, 176, 206, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .table th,
  .table td {
    padding: 15px;
    text-align: left;
    font-weight: bold;
    color: #333;
    font-size: 160px;
  }
  .table.custom-table th,
.table.custom-table td {
  font-size: 16px;
}

  .table thead {
    background-color: #27d4e0;
    color: #fff;
  }
  
  .table thead th {
    border-bottom: 2px solid #fff;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:hover {
    background-color: #e0e0e0;
  }
  